body {
  margin: 0;
  overflow: hidden; /* Hide the vertical scrollbar */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 1.1rem; /* Slightly smaller for readability */
  color: #555555; /* Softer gray for body text */
  line-height: 1.6; /* Improved readability */
  margin-left: auto;
  margin-right: auto; /* Ensure centered content */
  text-align: justify; /* Justified text for a clean block appearance */
}

code {
  font-family: "Courier New", Courier, monospace;
  font-size: 1rem; /* Same as body text size */
}

.title {
  font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 1.5rem; /* Equivalent to 24px */
  font-weight: bold;
  color: #1f1f1e;
}

.header {
  font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 1.125rem; /* Equivalent to 18px */
  font-weight: bold;
  color: #1f1f1e;
}

/* Bootstrap text classes */
.normal-text {
  font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 1rem; /* Equivalent to 16px */
  color: #1f1f1e;
}

.hover-box {
  transition: box-shadow 0.3s;
  background: #753636;
}

.hover-box:hover {
  box-shadow: 10px 10px 20px rgba(30, 8, 39, 0.2);
}

.sticky-column {
  position: sticky;
  top: 0;
  height: 100vh; /* Adjust the height as per your requirement */
}

.container {
  scroll-snap-type: y mandatory;
  overflow-y: scroll; /* Allows scrolling but hides the scrollbar */
  height: 100vh; /* Full screen height */
  width: 100vw; /* Full screen width */
  scrollbar-width: none; /* Hide scrollbar in Firefox */
  -ms-overflow-style: none; /* Hide scrollbar in IE and Edge */
}

.container::-webkit-scrollbar {
  display: none; /* Hide scrollbar in WebKit browsers */
}

.section {
  height: 100vh; /* Full screen height */
  display: flex;
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
  scroll-snap-align: start; /* Snap to the start of the section */
  padding: 0;
  margin: 0;
  border: none;
}

/* Add this to your CSS file or inside a <style> tag in your component */
.fade-in-section {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: translateY(0);
}

@font-face {
  font-family: "SF Normal";
  font-style: normal;
  font-weight: normal;
  src: local("SF Pro"),
    url("./fonts/SFPRODISPLAYREGULAR.OTF") format("truetype");
}

@font-face {
  font-family: "SF Bold";
  font-style: bold;
  font-weight: normal;

  src: local("SF Pro"), url("./fonts/SFPRODISPLAYBOLD.OTF") format("truetype");
}

@font-face {
  font-family: "SF Italic";
  font-style: italic;
  font-weight: normal;
  src: local("SF Pro"),
    url("./fonts/SFPRODISPLAYSEMIBOLDITALIC.OTF") format("truetype");
}

@font-face {
  font-family: "SF Italic Bold";
  font-style: italic;
  font-weight: bold;
  src: local("SF Pro"),
    url("./fonts/SFPRODISPLAYBLACKITALIC.OTF") format("truetype");
}
